import auth0 from "auth0-js";
import { decodeOauthQueryString } from "@lib/oauth-query-string-keys";
import localPlaywrightUrl from "../local-playwright-url";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import nextConfig from "../next.config";

if (
  !process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL ||
  !process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID ||
  !process.env.NEXT_PUBLIC_AUTH2_ISSUER_BASE_URL
) {
  throw new Error("Define env vars");
}

export const getUrlByEnvironment = () => {
  if (!!process.env.NEXT_PUBLIC_VERCEL_URL) {
    return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
  }

  if (localPlaywrightUrl.includes(":3000")) {
    return localPlaywrightUrl;
  }

  return "http://localhost:3000";
};

export const baseRedirectUri = getUrlByEnvironment();

type AuthProvider = {
  domain: string;
  clientID: string;
  redirectUri: string;
};

export function getConnectionFromQueryStringOrFragment(): string {
  if (typeof window === "undefined") return "auth0";

  const urlParams = new URLSearchParams(window.location.search);

  const connection = urlParams.get("connection");
  if (connection) {
    return connection;
  }

  const state = urlParams.get("state");
  if (state) {
    const oauthState = decodeOauthQueryString(state);
    if (oauthState.connection) {
      return oauthState.connection;
    }
  }

  const hash = window.location.hash;
  if (hash) {
    const hashParams = new URLSearchParams(hash);
    const state = hashParams.get("state");
    if (state) {
      const decodedState = decodeURIComponent(state);
      const oauthState = decodeOauthQueryString(decodedState);
      if (oauthState.connection) {
        return oauthState.connection;
      }
    }
  }

  return "auth0";
}

function getClientIdFromQueryStringOrFragment() {
  const urlParams = new URLSearchParams(window.location.search);

  const client_id = urlParams.get("client_id");

  if (client_id) {
    return client_id;
  }

  const state = urlParams.get("state");
  if (state) {
    const oauthState = decodeOauthQueryString(state);
    if (oauthState.client_id) {
      return oauthState.client_id;
    }
  }

  const hash = window.location.hash;
  if (hash) {
    const hashParams = new URLSearchParams(hash);
    const state = hashParams.get("state");
    if (state) {
      const decodedState = decodeURIComponent(state);
      const oauthState = decodeOauthQueryString(decodedState);
      if (oauthState.client_id) {
        return oauthState.client_id;
      }
    }
  }
}

function getAuthProvider(): AuthProvider {
  const connection = getConnectionFromQueryStringOrFragment();

  if (!["auth0", "auth2"].includes(connection)) {
    // we are on an unknown auth provider
    throw new Error("No auth provider found");
  }

  const redirectUri = `${getUrlByEnvironment()}/callback`;

  if (connection === "auth0") {
    return {
      domain: process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL!,
      clientID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!,
      redirectUri,
    };
  }

  // on auth2 we pull the client_id from the query string
  const clientId = getClientIdFromQueryStringOrFragment();

  // should we do similar with vendor_id? so if auth2, we need the vendor_id also?
  if (!clientId) throw new Error("client_id needed for Auth2 connection");

  return {
    domain: process.env.NEXT_PUBLIC_AUTH2_ISSUER_BASE_URL!,
    clientID: clientId,
    redirectUri,
  };
}

const authProvider = getAuthProvider();

export const webAuth = new auth0.WebAuth({
  domain: authProvider.domain,
  clientID: authProvider.clientID,
  scope: "openid profile email", // should we be reading the scope from the querystring?
  redirectUri: authProvider.redirectUri,
  responseType: "token id_token",
});

export type CheckSessionResponse = {
  accessToken: string;
  appState: string;
  expiresIn: number;
  idToken: string;
  idTokenPayload: {
    at_hash: string;
    aud: string;
    email: string;
    email_verified: boolean;
    exp: number;
    iat: number;
    iss: string;
    name: string;
    nickname: string;
    nonce: string;
    picture: string;
    sid: string;
    sub: string;
    updated_at: string;
  };
  refreshToken: null;
  scope: string;
  state: string;
  tokenType: string;
};

export function checkSession() {
  return new Promise<CheckSessionResponse>((resolve, reject) => {
    webAuth.checkSession({}, function (err, authResult: CheckSessionResponse) {
      if (!err) {
        datadogLogs.logger.info("Successful login");

        datadogLogs.setUser({
          id: authResult.idTokenPayload.sub,
          name: authResult.idTokenPayload.name,
          email: authResult.idTokenPayload.email,
        });

        datadogRum.setUser({
          id: authResult.idTokenPayload.sub,
          name: authResult.idTokenPayload.name,
          email: authResult.idTokenPayload.email,
        });

        resolve(authResult);
      } else {
        reject(err);
      }
    });
  });
}

import {
  JSXElementConstructor,
  FC,
  ButtonHTMLAttributes,
  forwardRef,
  PropsWithChildren,
} from "react";
import cn from "classnames";
import Spinner from "@components/ui/Spinner";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  variant?: "primary" | "secondary" | "custom";
  bgColor?: "primary";
  textColor?: "black" | "white";
  Component?: string | JSXElementConstructor<any>;
  disabled?: boolean;
  isLoading?: boolean;
}

const Button: FC<PropsWithChildren<ButtonProps>> = forwardRef(
  (
    {
      children,
      className = "",
      variant = "primary",
      bgColor = "primary",
      textColor = "white",
      Component = "button",
      disabled = false,
      isLoading = false,
      ...rest
    },
    buttonRef,
  ) => {
    return (
      <Component
        ref={buttonRef}
        disabled={disabled}
        className={cn(
          "relative w-full rounded-lg text-center",
          {
            "px-4 py-5": variant !== "custom",
            "text-white": bgColor !== "primary" && textColor === "white",
            "text-black": bgColor !== "primary" && textColor === "black",
            "bg-primary text-textOnPrimary hover:bg-primaryHover":
              variant === "primary" && bgColor === "primary",
            "border border-gray-300 bg-white text-black":
              variant === "secondary",
            "pointer-events-none cursor-not-allowed opacity-40": disabled,
          },
          className,
        )}
        {...rest}
      >
        <span
          className={`
            flex items-center justify-center space-x-2
            ${isLoading ? "opacity-0" : ""}
          `}
        >
          {children}
        </span>

        {isLoading && (
          <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
            <Spinner size="medium" />
          </div>
        )}
      </Component>
    );
  },
);

Button.displayName = "Button";
export default Button;

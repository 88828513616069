import type { NextPage } from "next";
import Head from "next/head";
import {
  webAuth,
  baseRedirectUri,
  checkSession,
  CheckSessionResponse,
} from "../auth0/auth0";
import { useState, useEffect } from "react";
import { useRouter } from "next/router";

import Button from "@components/ui/Button";
import { Trans, useTranslation } from "react-i18next";
import {
  createOauthQuerystringParams,
  getRedirectUriWithOAuthQueryStringParams,
  decodeOauthQueryString,
} from "@lib/oauth-query-string-keys";
import { ParsedUrlQuery } from "querystring";
import Layout from "@components/Layout";
import { flattenQueryStringValuePossiblyNull } from "@lib/utils";
import { Auth0Error } from "auth0-js";
import { datadogLogs } from "@datadog/browser-logs";
import { NextRouter } from "next/router";

async function handleLogout(router: NextRouter) {
  const oauthQuerystringParams = createOauthQuerystringParams(router.query);

  webAuth.logout({
    returnTo: `${baseRedirectUri}/?${oauthQuerystringParams}`,
  });
}

function isStandAlone(query: ParsedUrlQuery) {
  if (typeof window === "undefined") return true;

  if (query.redirect_uri) {
    return false;
  }

  return true;
}

const Home: NextPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const [session, setSession] = useState<CheckSessionResponse>();

  const standAloneLogin2 = isStandAlone(router.query);

  const connection = flattenQueryStringValuePossiblyNull(
    router.query.connection,
  );

  const clientId = flattenQueryStringValuePossiblyNull(router.query.client_id);
  const vendorId = flattenQueryStringValuePossiblyNull(router.query.vendor_id);

  useEffect(() => {
    async function checkSessionSetState() {
      try {
        const checkSessionResponse = await checkSession();
        setSession(checkSessionResponse);
      } catch (e) {
        // checkSession will throw an error if the user is not logged in

        const auth0Error = e as Auth0Error;

        if (auth0Error.error !== "login_required") {
          console.error(e);
          datadogLogs.logger.info("Error checking session", {
            error: auth0Error,
          });
        }

        const oauthQuerystringParams = createOauthQuerystringParams(
          router.query,
        );

        router.replace(`/enter-email?${oauthQuerystringParams}`);
      }
    }

    if (!router.isReady) return;

    checkSessionSetState();
  }, [router]);

  // this code is almost the same as on /callback... see comments there
  const handleContinue = async () => {
    if (!session) {
      throw new Error("no session");
    }
    datadogLogs.logger.info("Continue with...");

    const oauthQuerystringParams = createOauthQuerystringParams(router.query);

    const decodedOAuthState = decodeOauthQueryString(oauthQuerystringParams);

    if (!decodedOAuthState.redirect_uri) {
      router.replace("/");
      return;
    }

    const redirectURLWithOAuthQueryStringParams =
      getRedirectUriWithOAuthQueryStringParams(oauthQuerystringParams);

    if (decodedOAuthState.client_id && !decodedOAuthState.redirect_uri) {
      throw new Error("redirect_uri is required");
    }

    if (!decodedOAuthState.client_id && decodedOAuthState.redirect_uri) {
      window.location.href = decodedOAuthState.redirect_uri;

      return;
    }

    const { accessToken, idToken } = session;

    window.location.href = `${redirectURLWithOAuthQueryStringParams}&access_token=${accessToken}&id_token=${idToken}`;
    return;
  };

  const handleChangeAccount = async () => {
    datadogLogs.logger.info("Change account...");

    const oauthQuerystringParams = createOauthQuerystringParams(router.query);

    webAuth.logout({
      returnTo: `${baseRedirectUri}/?${oauthQuerystringParams}`,
    });
  };

  let pageTitle = t("sign_in");
  if (!!session && !standAloneLogin2) {
    pageTitle = t("account_detected");
  }

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <Layout>
        <div className="mb-4 text-lg font-medium sm:text-2xl">{pageTitle}</div>

        {!!session && !standAloneLogin2 && (
          <>
            <div className="mb-8 text-gray-300">
              <Trans
                i18nKey="currently_logged_in_as"
                components={[
                  <span className="text-black dark:text-white" key="span" />,
                ]}
                values={{ email: session.idTokenPayload.email }}
              />
              .<br />
              {t("continue_with_sso_provider_headline")}
            </div>

            <div className="flex flex-1 flex-col justify-center">
              <div className="space-y-6">
                <Button
                  onClick={handleContinue}
                  className="border border-gray-200 bg-white px-10 py-3 hover:bg-gray-100 dark:border-gray-400 dark:bg-black dark:hover:bg-black/90"
                  variant="custom"
                >
                  {t("yes_continue_with_existing_account")}
                </Button>
                <button
                  className="mx-auto block text-primary hover:text-primaryHover"
                  onClick={handleChangeAccount}
                >
                  {t("no_use_another")}
                </button>
              </div>
            </div>
          </>
        )}
        {!!session && standAloneLogin2 && (
          <>
            <div className="mb-8 text-gray-300">
              {t("logged_in_as")}&nbsp;
              {session.idTokenPayload.email}
            </div>
            <div className="flex flex-1 flex-col justify-center">
              <Button onClick={() => handleLogout(router)}>
                {t("logout")}
              </Button>
            </div>
          </>
        )}
        {!session && (
          <>
            <div className="text-gray-300">{t("redirecting")}...</div>
            <div className="flex flex-1 flex-col justify-center" />
          </>
        )}
      </Layout>
    </>
  );
};

export default Home;
